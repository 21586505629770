import React from "react"

const Colors = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="max-w-3xl w-full">
        <p className="m-2">
          <p className="font-bold">Primary color: </p> This is where the user’s
          attention goes. Calls-to-action, buttons, and any other important
          information should utilize the primary color.
        </p>
        <p className="m-2">
          <p className="font-bold">Secondary color:</p> The secondary colors are
          used to highlight less important elements. Secondary action buttons,
          less important text, and anything else that doesn’t need immediate
          attention should be presented in a secondary color.
        </p>
        <p className="m-2">
          <p className="font-bold">Neutral/Additional colors:</p> Neutrals are
          typically used for text, backgrounds, or anything else that does not
          need to compete for attention.
        </p>

        <h1 className="text-lg mt-8">Primary</h1>
        <Color className="bg-pri-300" />
        <Color className="bg-pri-500" />
        <Color className="bg-pri-700" />
        <h1 className="text-lg mt-8">Secondary1</h1>
        <Color className="bg-sec1-300" />
        <Color className="bg-sec1-500" />
        <Color className="bg-sec1-700" />
        <h1 className="text-lg mt-8">Secondary2</h1>
        <Color className="bg-sec2-300" />
        <Color className="bg-sec2-500" />
        <Color className="bg-sec2-700" />
        <h1 className="text-lg mt-8">Secondary3</h1>
        <Color className="bg-sec3-300" />
        <Color className="bg-sec3-500" />
        <Color className="bg-sec3-700" />
      </div>
    </div>
  )
}

const Color = (props: { className: string }) => {
  return (
    <div className={"w-full h-32 " + props.className}>
      <p>{props.className}</p>
    </div>
  )
}

export default Colors
